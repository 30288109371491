import * as API from "services/api-meta-system";
import { exclude, isEmpty } from "utils/helpers/utility";
import ActionTypes from "utils/constants/action-types";
import { setPopup } from "./popupActions";
import { POPUP_TYPE } from "utils/constants";

export const createMetaSystem =
  (params, setLoading = () => {}, history) =>
  (dispatch, getState) => {
    API.createMetaSystem(params)
      .then(({ data, autoCreatedSystems }) => {
        const metaSystem = getState().projects.metaSystemClone;
        if (metaSystem) {
          const {
            mainSystem: { deliverables, tasks, escalations },
          } = metaSystem;
          dispatch(
            initDeliverables({
              _id: data.mainSystem._id,
              deliverables: deliverables.map((d) => exclude(d, ["calculated"])),
              escalations: escalations,
              tasks: tasks,
            })
          );
        }

        dispatch({
          type: ActionTypes.CREATE_META_SYSTEM,
          payload: { data, autoCreatedSystems },
        });
        setLoading(false);
        if (metaSystem) {
          history?.goBack();
        }
        history?.goBack();
      })
      .catch((err) => {
        setLoading(false);
        history?.goBack();
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText:
              err.response?.data?.info || "Error creating meta systems",
          })
        );
        console.error("[createMetaSystem] error => ", err);
      });
  };

export const readMetaSystem =
  (
    { project = null, system = null, mainSystem = null, name = null } = {},
    refresh = false,
    setLoading = () => {}
  ) =>
  async (dispatch, getState) => {
    const {
      projects: { metaSystems, metaSystemClone },
    } = getState();

    if (
      project &&
      metaSystems.filter((s) => s.project === project) &&
      !refresh
    ) {
      return setLoading(false);
    } else if (
      system &&
      !isEmpty(metaSystems.find((s) => s._id === system)) &&
      !refresh
    ) {
      return setLoading(false);
    } else if (!isEmpty(metaSystemClone)) {
      return setLoading(false);
    }

    const params = {
      sort: "name",
      filter: {
        project: project || undefined,
        _id: system || undefined,
        mainSystem: mainSystem || undefined,
        name: null,
      },
    };

    await API.getMetaSystems(params)
      .then((resp) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.FETCH_SYSTEMS_WORKFLOWS,
          payload: resp.workflows,
        });
        dispatch({ type: ActionTypes.FETCH_META_SYSTEMS, payload: resp.data });
      })
      .catch((err) => {
        setLoading(false);
        console.error("[readMetaSystem] error => ", err);
      });
  };

export const updateMetaSystem =
  (params, loading = () => {}) =>
  async (dispatch) => {
    try {
      loading(true);
      const { data } = await API.updateMetaSystem(params);
      dispatch({
        type: ActionTypes.UPDATE_META_SYSTEM,
        payload: data,
      });
      return data;
    } catch (err) {
      console.error("[updateMetaSystem] error => ", err);
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: err.response?.data?.info || "Error updating meta systems",
        })
      );
    } finally {
      loading(false);
    }
  };

export const deleteMetaSystem =
  (project, system, childrenSystems) => async (dispatch) => {
    try {
      const res = await API.deleteMetaSystem({ _id: system, childrenSystems });
      dispatch({
        type: ActionTypes.DELETE_META_SYSTEM,
        payload: { project, system, childrenSystems },
      });
      return res;
    } catch (err) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: err.response?.data?.info || "Error deleting system",
        })
      );
      throw err?.response?.data;
    }
  };

export const duplicateMetaSystem = (system) => ({
  type: ActionTypes.DUPLICATE_META_SYSTEM,
  payload: system,
});

export const updateDeliverables = (payload) => ({
  type: ActionTypes.UPDATE_DELIVERABLES,
  payload,
});

export const updateDeliverableNotes = (mainId, deliverable, notes) => ({
  type: ActionTypes.UPDATE_DELIVERABLE_NOTES,
  payload: { mainId, deliverable, notes },
});

export const initDeliverables =
  (params, setLoading = () => {}, changeTemplate) =>
  (dispatch) => {
    API.initDeliverables(params)
      .then(({ data }) => {
        setLoading(false);
        if (changeTemplate) {
          dispatch(
            setPopup({
              popupType: POPUP_TYPE.info,
              popupText: "Template Changed Successfully",
            })
          );
        }
        dispatch(updateDeliverables(data));
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText:
              err.response?.data?.info || "Error initializing template",
          })
        );
        console.error("[initDeliverables] error => ", err);
      });
  };

export const createDeliverable =
  (params, setLoading = () => {}) =>
  (dispatch) => {
    API.createDeliverable(params)
      .then(({ data }) => {
        dispatch(updateDeliverables(data));
        setLoading(false);
      })
      .catch((err) => {
        console.error("[createDeliverable] error => ", err);
        setLoading(false);
      });
  };

export const updateDeliverable =
  (params, setLoading = () => {}) =>
  async (dispatch) => {
    setLoading(true);
    await API.updateDeliverable(params)
      .then(({ data }) => {
        dispatch(updateDeliverables(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err.response?.data?.info || "Error updating deliverable",
          })
        );
        setLoading(false);

        console.error("[updateDeliverable] error => ", err);
      });
  };
export const updateDeliverableOrder =
  (params, setLoading = () => {}) =>
  async (dispatch) => {
    await API.updateDeliverableOrder(params)
      .then(({ data }) => {
        dispatch(updateDeliverables(data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err.response?.data?.info || "Error updating deliverable",
          })
        );

        console.error("[updateDeliverables order] error => ", err);
      });
  };

export const createDeliverableNote = (params) => async (dispatch) => {
  await API.createDeliverableNote(params)
    .then(({ data }) =>
      dispatch(updateDeliverableNotes(params.mainId, params._id, data))
    )
    .catch((err) => console.error("[createDeliverableNote] error => ", err));
};

export const updateDeliverableNote = (params) => async (dispatch) => {
  await API.updateDeliverableNote(params)
    .then(({ data }) => {
      dispatch(updateDeliverableNotes(params.mainId, params._id, data));
    })
    .catch((err) => {
      console.error("[updateDeliverableNote] error => ", err);
    });
};

export const deleteDeliverableNote = (params) => async (dispatch) => {
  await API.deleteDeliverableNote(params)
    .then(({ data }) =>
      dispatch(updateDeliverableNotes(params.mainId, params._id, data))
    )
    .catch((err) => console.error("[deleteDeliverableNote] error => ", err));
};

export const deleteDeliverable =
  (params, setLoading = () => {}) =>
  async (dispatch) => {
    await API.deleteDeliverable(params)
      .then(({ data }) => {
        dispatch(updateDeliverables(data));
        setLoading(false);
        return data;
      })
      .catch((err) => {
        console.error("[deleteDeliverable] error => ", err);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err.response?.data?.info || "Error deleting deliverable",
          })
        );
        setLoading(false);
        throw err;
      });
  };

export const updateDeliverablePositions = (params) => (dispatch) => {
  API.updateDeliverablePositions(params)
    .then(({ data }) => dispatch(updateDeliverables(data)))
    .catch((err) =>
      console.error("[updateDeliverablePositions] error => ", err)
    );
};

export const getSystemHistory =
  (projectId, refresh = false) =>
  async (dispatch, getState) => {
    try {
      const {
        projects: { systemTrends },
      } = getState();

      if (systemTrends[projectId] && !refresh) {
        return;
      }

      const params = {
        filter: { project: projectId },
      };

      const response = await API.getSystemHistory(params);

      if (response) {
        const { data } = response;

        dispatch({
          type: ActionTypes.FETCH_SYSTEM_TRENDS,
          payload: { data, projectId },
        });
      }
    } catch (error) {
      console.log("[getSystemHistory] error => ", error);
    }
  };

export const createTask = (systemId, params) => async (dispatch) => {
  await API.createMainTask(systemId, params)
    .then((data) => dispatch(updateTasks(data)))
    .catch((err) => console.error("[createDeliverable] error => ", err));
};

export const createEscalation = (params) => async (dispatch) => {
  await API.createEscalation(params)
    .then((data) => dispatch(updateEscalations(data)))
    .catch((err) => console.error("[createEscalation] error => ", err));
};

export const updateMainTasks = (params) => async (dispatch) => {
  await API.updateMainTask(params.mainId, params._id, params)
    .then(async (data) => await dispatch(updateTasks(data)))
    .catch((err) => {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: err.response?.data?.message || "Error updating task",
        })
      );
      console.error("[updateDeliverable] error => ", err);
    });
};

export const updateEscalation = (params) => async (dispatch) => {
  await API.updateEscalation(params)
    .then(async (data) => await dispatch(updateEscalations(data)))
    .catch((err) => {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: err.response?.data?.message || "Error updating escalation",
        })
      );
      console.error("[updateEscalation] error => ", err);
    });
};

export const deleteTask = (systemId, taskId) => async (dispatch) => {
  await API.deleteMainTask(systemId, taskId)
    .then(async (data) => await dispatch(updateTasks(data)))
    .catch((err) => console.error("[deleteDeliverableNote] error => ", err));
};

export const deleteEscalation = (mainId, escalationId) => async (dispatch) => {
  await API.deleteEscalation(mainId, escalationId)
    .then(async (data) => {
      await dispatch(updateEscalations(data));
    })
    .catch((err) => console.error("[deleteEscalation] error => ", err));
};

export const updateTasks = (payload) => ({
  type: ActionTypes.UPDATE_TASKS,
  payload,
});

export const updateEscalations = (payload) => ({
  type: ActionTypes.UPDATE_ESCALATIONS,
  payload,
});

export const createSubTask = (systemId, taskId, params) => async (dispatch) => {
  await API.createSubTask(systemId, taskId, params)
    .then(async (data) => {
      const task = data.tasks.filter((task) => task._id === taskId);
      await dispatch(updateSubTask(systemId, taskId, task[0].subTasks));
    })
    .catch((err) => console.error("[createSubTask] error => ", err));
};

export const createSubEscalation =
  (systemId, escalationId, params, setLoading = () => {}) =>
  async (dispatch) => {
    setLoading(true);
    await API.createSubEscalation(params)
      .then(async (resp) => {
        setLoading(false);
        await dispatch(updateSubEscalations(systemId, escalationId, resp.data));
      })
      .catch((err) => {
        setLoading(false);
        console.error("[createSubEscalation] error => ", err);
      });
  };

export const updateSubTasks =
  (systemId, taskId, params) => async (dispatch) => {
    await API.updateSubTask(systemId, taskId, params)
      .then(async (data) => {
        await dispatch(updateTasks(data));
      })
      .catch((err) => console.error("[createSubTask] error => ", err));
  };

export const updateSubEscalation =
  (systemId, escalationId, params) => async (dispatch) => {
    await API.updateSubEscalation(params)
      .then(async (resp) => {
        await dispatch(updateSubEscalations(systemId, escalationId, resp.data));
      })
      .catch((err) => console.error("[updateSubEscalation] error => ", err));
  };

export const deleteSubTasks =
  (systemId, taskId, subTaskId) => async (dispatch) => {
    await API.deleteSubTask(systemId, taskId, subTaskId)
      .then(async (data) => {
        await dispatch(updateTasks(data));
      })
      .catch((err) => console.error("[createSubTask] error => ", err));
  };

export const deleteSubEscalation =
  (systemId, escalationId, subEscalationId) => async (dispatch) => {
    await API.deleteSubEscalation(subEscalationId, systemId, escalationId)
      .then(async (resp) => {
        await dispatch(updateSubEscalations(systemId, escalationId, resp.data));
      })
      .catch((err) => console.error("[deleteSubEscalation] error => ", err));
  };

export const updateSubTask = (mainId, task, subTasks) => ({
  type: ActionTypes.UPDATE_SUBTASK,
  payload: { mainId, task, subTasks },
});

export const updateSubEscalations = (mainId, escalation, subEscalations) => ({
  type: ActionTypes.UPDATE_SUBESCALATION,
  payload: { mainId, escalation, subEscalations },
});
