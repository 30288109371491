import { restrict } from "utils/helpers/utility";
import { roundMainSystem, roundMainSystemTasks } from "./helper";

export const INITIAL_STATE = Object.freeze({
  results: [],
  workflows: [],
  pagination: { count: 0 },
  metaSystems: [],
  metaSystemsFilter: [],
  isMetaSystemsLoading: false,
  systemTrends: {},
  metaSystemClone: null,
  insights: {
    projectDetails: {},
    systems: [],
  },
  phases: {
    phaseDetail: { phaseData: [] },
  },
  phaseDetail: {
    phaseData: [],
    phaseDetail: {
      systemData: [],
      masterSystemData: [],
      completedCount: 0,
      notStarted: 0,
      inProgressCount: 0,
      totalCount: 0,
      releaseToUse: 0,
    },
    deliverableDetail: {
      deliverableData: [],
    },
  },
  customNotifications: {
    results: [],
    count: 0,
  },
  qmsProject: {},
});

export const fetchProjectsUpdater = (state, { payload }) => ({
  ...state,
  results: payload.data,
  pagination: payload.pagination || state.pagination,
  phases: INITIAL_STATE.phases,
  phaseDetail: INITIAL_STATE.phaseDetail,
});

export const fetchCustomNotificationsUpdater = (state, { payload }) => ({
  ...state,
  customNotifications: {
    results: [...state.customNotifications.results, ...payload.response],
    count: payload.count,
  },
});

export const deleteCustomNotificationsUpdater = (state, { payload }) => ({
  ...state,
  customNotifications: {
    results: [...state.customNotifications.results, ...payload.response],
    count: payload.count,
  },
});

export const clearCustomNotificationsUpdater = (state, { payload }) => ({
  ...state,
  customNotifications: {
    results: payload.response,
    count: payload.count,
  },
});

export const addProjectUpdater = (state, { payload }) => ({
  ...state,
  results: [payload, ...state.results],
});

export const editProjectUpdater = (state, { payload }) => {
  const { _id: projectId } = payload;
  const { results } = state;
  const newResults = results.map((project) =>
    project._id === projectId ? payload : project
  );

  return {
    ...state,
    results: newResults,
  };
};

export const deleteProjectUpdater = (state, { payload }) => {
  const { results } = state;
  const { _id: projectId } = payload;
  const newProjects = results.filter(({ _id }) => _id !== projectId);

  return {
    ...state,
    results: newProjects,
  };
};

export const fetchMetaSystemsUpdater = (state, { payload }) => ({
  ...state,
  metaSystems: payload.map((meta) => ({
    ...meta,
    mainSystem: roundMainSystem(meta.mainSystem),
  })),
});

export const systemWorkflowUpdater = (state, { payload }) => {
  return {
    ...state,
    workflows: payload,
  };
};

export const createMetaSystemUpdater = (state, { payload }) => {
  const {
    data: { project, _id },
    autoCreatedSystems,
  } = payload;
  const autoIds = autoCreatedSystems.map((s) => s._id);

  return {
    ...state,
    results: state.results.map((p) =>
      p._id === project
        ? { ...p, metaSystems: [...p.metaSystems, _id, ...autoIds] }
        : p
    ),
    metaSystems: [...state.metaSystems, payload.data, ...autoCreatedSystems],
    metaSystemClone: null,
  };
};

export const updateMetaSystemUpdater = (state, { payload }) => {
  const restricted = restrict(payload, [
    "_id",
    "autoPhaseId",
    "name",
    "equipmentCategory",
    "equipmentType",
    "equipmentName",
    "equipmentNumber",
    "project",
    "productCode",
    "site",
    "projectPhase",
    "release",
    "area",
    "linkedSystems"
  ]);

  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms._id === payload._id ? { ...ms, ...restricted } : ms
    ),
  };
};

export const deleteMetaSystemUpdater = (
  state,
  { payload: { project, system, childrenSystems } }
) => ({
  ...state,
  metaSystems: state.metaSystems
    .filter((ms) => ms._id !== system)
    .filter((ms) => (childrenSystems ? ms?.parentSystem !== system : true)),
  results: state.results.map((p) =>
    p._id === project
      ? {
          ...p,
          metaSystems: p.metaSystems.filter((msid) => msid !== system),
        }
      : p
  ),
});

export const duplicateMetaSystemUpdater = (state, { payload }) => ({
  ...state,
  metaSystemClone: payload,
});

export const setActiveProject = (state, { payload }) => ({
  ...state,
  activeProject: payload,
});

export const fetchSystemTrendsUpdater = (
  state,
  { payload: { data, projectId } }
) => ({
  ...state,
  systemTrends: {
    ...state.systemTrends,
    [projectId]: data,
  },
});

export const getPhases = (state, { payload }) => ({
  ...state,
  phases: {
    ...payload,
  },
});
export const getInsights = (state, { payload }) => ({
  ...state,
  insights: {
    ...payload,
  },
});
export const getPhaseDetail = (state, { payload }) => ({
  ...state,
  phaseDetail: {
    ...payload,
  },
});

export const getMultiPhaseDetail = (state, { payload }) => {
  if (payload.added) {
    const tempPhaseData = state.phaseDetail?.phaseData
      ? [...state.phaseDetail?.phaseData, ...payload.data.data.phaseData]
      : [...payload.data.data.phaseData];
    const tempSystemData = [
      ...state?.phaseDetail?.phaseDetail?.systemData,
      ...payload.data.data.phaseDetail?.systemData,
    ];
    // const tempmasterSystemData = [
    //   ...state?.phaseDetail?.phaseDetail?.masterSystemData,
    //   ...payload.data.data.phaseDetail?.masterSystemData,
    // ];
    const tempDeliverableData = [
      ...state?.phaseDetail?.deliverableDetail?.deliverableData,
      ...payload.data.data.deliverableDetail?.deliverableData,
    ];

    const finalObject = {
      phaseData: tempPhaseData,
      phaseDetail: {
        systemData: tempSystemData,
        masterSystemData: [...payload.data.data.phaseDetail?.masterSystemData],
      },
      deliverableDetail: { deliverableData: tempDeliverableData },
    };

    return {
      ...state,
      phaseDetail: {
        ...finalObject,
      },
    };
  } else {
    return {
      ...state,
      phaseDetail: {
        ...payload.data.data,
      },
    };
  }
};

export const updateDeliverablesUpdater = (state, { payload }) => {
  const { metaSystem } = payload;
  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms._id === metaSystem
        ? { ...ms, mainSystem: roundMainSystem(payload) }
        : ms
    ),
  };
};

export const updateDeliverableNotesUpdater = (
  state,
  { payload: { mainId, deliverable, notes } }
) => {
  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms.mainSystem._id === mainId
        ? {
            ...ms,
            mainSystem: {
              ...ms.mainSystem,
              deliverables: ms.mainSystem.deliverables.map((d) =>
                d._id === deliverable ? { ...d, notes } : d
              ),
            },
          }
        : ms
    ),
  };
};

export const updateTasksUpdater = (state, { payload }) => {
  const { metaSystem } = payload;
  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms._id === metaSystem
        ? { ...ms, mainSystem: roundMainSystemTasks(payload) }
        : ms
    ),
  };
};

export const updateEscalationUpdater = (state, { payload }) => {
  const { data } = payload;
  return {
    ...state,
    metaSystems: state.metaSystems?.map((ms) =>
      ms?._id === data?.metaSystem
        ? { ...ms, mainSystem: roundMainSystemTasks(data) }
        : ms
    ),
  };
};

export const updateSubTaskUpdater = (
  state,
  { payload: { mainId, task, subTasks } }
) => {
  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms.mainSystem._id === mainId
        ? {
            ...ms,
            mainSystem: {
              ...ms.mainSystem,
              tasks: ms.mainSystem.tasks.map((d) =>
                d._id === task ? { ...d, subTasks } : d
              ),
            },
          }
        : ms
    ),
  };
};

export const updateSubEscalationUpdater = (
  state,
  { payload: { mainId, escalation, subEscalations } }
) => {
  return {
    ...state,
    metaSystems: state.metaSystems.map((ms) =>
      ms.mainSystem._id === mainId
        ? {
            ...ms,
            mainSystem: {
              ...ms.mainSystem,
              escalations: ms.mainSystem.escalations.map((d) =>
                d._id === escalation ? { ...d, subEscalations } : d
              ),
            },
          }
        : ms
    ),
  };
};

export const qmsProjectUpdater = (state, { payload }) => {
  return {
    ...state,
    qmsProject: payload,
  };
};
