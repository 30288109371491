import { memo } from "react";
import {
  Grid,
  LinearProgress,
  styled,
  Stepper,
  Step,
  StepButton,
  Tooltip,
} from "@material-ui/core";

import _ from "lodash";
import moment from "moment";
import {
  colorIFRA,
  colorRA,
  getFormattedDate,
} from "pages/Projects/ProjectInsight/components/DeliverableSnapshot/snapshotHelper";
import "./statusTimeline.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

const DeliverableStatusTimeline = (props) => {
  const { deliverable, editCard, showStatus, timelineType, isWorkflowCall} = props;
  const { end, completion, status } = deliverable;

  let today = new Date();
  today = today.toISOString();

  const getStyle = (completion, end, status) => {
    if(isWorkflowCall){
      return "grayBackgroundColor"
    }

    return getFormattedDate(today) > getFormattedDate(end) &&
      !completion &&
      Math.round(status) < 100
      ? "redBackground"
      : completion
      ? getFormattedDate(end) >= getFormattedDate(completion) &&
        Math.round(status) === 100
        ? "greenBackground"
        : getFormattedDate(end) < getFormattedDate(completion) &&
          Math.round(status) === 100
        ? "orangeBackground"
        : "primaryBackGround"
      : "primaryBackGround";
  };
  const getClassname = (completion, end, status) => {

    if(isWorkflowCall){
      return "gray"
    }

    return getFormattedDate(today) > getFormattedDate(end) &&
      !completion &&
      Math.round(status) < 100
      ? "red"
      : completion
      ? getFormattedDate(end) >= getFormattedDate(completion) &&
        Math.round(status) === 100
        ? "green"
        : getFormattedDate(end) < getFormattedDate(completion) &&
          Math.round(status) === 100
        ? "orange"
        : "primary"
      : "primary";
  };
  const getDatesOnHover = (start, end) => {
    const startDate = moment(start?.split("T")[0])?.format("MM/DD/YY");
    const endDate = moment(end?.split("T")[0])?.format("MM/DD/YY");
    return startDate + " - " + endDate;
  };

  return (
    <div>
      <Grid container column={12} spacing={2}>
        <Grid item xs={11}>
          {timelineType && (
            <div>
              <Grid
                container
                column={12}
                spacing={2}
                className="txt-sm"
                justify="space-around"
              >
                <Grid item xs={2} className="d-jsb-cen">
                  Draft
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  IFR
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  Reviewed
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  IFA
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  Approved
                </Grid>
                {deliverable?.master && (
                  <Grid item xs={2} className="d-jsb-cen">
                    Master
                  </Grid>
                )}
              </Grid>
              <Stepper alternativeLabel style={{ padding: "0px" }}>
                <Tooltip
                  title={getDatesOnHover(
                    deliverable?.draft?.start,
                    deliverable?.draft?.end
                  )}
                >
                  <Step>
                    <StepButton
                      icon={
                        <div
                          className="timeline-dot"
                          style={{
                            backgroundColor: colorRA(
                              deliverable?.draft?.completion,
                              deliverable?.draft?.end,
                              deliverable?.draft?.status,
                              "timeline",
                              isWorkflowCall,
                            ),
                          }}
                        ></div>
                      }
                    ></StepButton>
                  </Step>
                </Tooltip>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.ifr?.start,
                      deliverable?.ifr?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          <div
                            className="timeline-dot"
                            style={{
                              backgroundColor: colorIFRA(
                                deliverable?.ifr?.completion,
                                deliverable?.ifr?.end || deliverable?.start,
                                deliverable?.ifr?.issued,
                                "timeline",
                                isWorkflowCall,
                              ),
                            }}
                          ></div>
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>

                  <LinearProgress
                    variant="determinate"
                    value={Math.round(deliverable?.draft?.status || 0)}
                    className="timeline-connector"
                  ></LinearProgress>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.review?.start,
                      deliverable?.review?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          <div
                            className="timeline-dot"
                            style={{
                              backgroundColor: colorRA(
                                deliverable?.review?.completion,
                                deliverable?.review?.end,
                                deliverable?.review?.status,
                                "timeline",
                                isWorkflowCall,
                              ),
                            }}
                          ></div>
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(deliverable?.review?.status || 0)}
                    className="timeline-connector"
                  ></LinearProgress>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.ifa?.start,
                      deliverable?.ifa?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          <div
                            className="timeline-dot"
                            style={{
                              backgroundColor: colorIFRA(
                                deliverable?.ifa?.completion,
                                deliverable?.ifa?.end || deliverable?.start,
                                deliverable?.ifa?.issued,
                                "timeline",
                                isWorkflowCall,
                              ),
                            }}
                          ></div>
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <LinearProgress
                    variant="determinate"
                    value={
                      Math.round(deliverable?.review?.status || 0) === 100 &&
                      deliverable?.ifr?.issued &&
                      deliverable?.ifa?.issued
                        ? 100
                        : 0
                    }
                    className="timeline-connector"
                  ></LinearProgress>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.approval?.start,
                      deliverable?.approval?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          <div
                            className="timeline-dot"
                            style={{
                              backgroundColor: colorRA(
                                deliverable?.approval?.completion,
                                deliverable?.approval?.end,
                                deliverable?.approval?.status,
                                "timeline",
                                isWorkflowCall,
                              ),
                            }}
                          ></div>
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(deliverable?.approval?.status || 0)}
                    className="timeline-connector"
                  ></LinearProgress>
                </Step>
                {deliverable?.master && (
                  <Step>
                    <Tooltip
                      title={getDatesOnHover(
                        deliverable?.masterDate?.start,
                        deliverable?.masterDate?.end
                      )}
                    >
                      <Step>
                        <StepButton
                          icon={
                            <div
                              className="timeline-dot"
                              style={{
                                backgroundColor: colorIFRA(
                                  deliverable?.masterDate?.completion,
                                  deliverable?.masterDate?.end,
                                  deliverable?.masterFile,
                                  "timeline",
                                  isWorkflowCall,
                                ),
                              }}
                            ></div>
                          }
                        ></StepButton>
                      </Step>
                    </Tooltip>

                    <LinearProgress
                      variant="determinate"
                      value={deliverable?.masterFile ? 100 : 0}
                      className="timeline-connector"
                    ></LinearProgress>
                  </Step>
                )}
              </Stepper>
              <Grid container justify="space-between">
                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.end?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          {!timelineType && (
            <div>
              <Grid container justify="space-around">
                <Grid item xs={10}>
                  <div
                    className={getClassname(completion, end, status)}
                    style={{ padding: "2px" }}
                  >
                    <BorderLinearProgress
                      className={getStyle(completion, end, status)}
                      style={{
                        marginTop: "1rem",
                      }}
                      variant="determinate"
                      value={_.round(deliverable?.status || 0)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.end?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item xs={1}>
          {showStatus && (
            <Grid item xs={2} className="d-jsb-c">
              <Grid
                container
                style={{
                  marginLeft: editCard ? "45px" : "30px",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12}>
                  <div>{deliverable?.status}%</div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(DeliverableStatusTimeline);
